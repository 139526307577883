import firebase from 'firebase/compat/app';
import { DatabaseUpdateParser } from '../DatabaseUpdateParser.js';

export function getParser() {
  return DatabaseUpdateParser.fromConfig({
    globalPathFilter: /^photoheight\/jobs\/*./,
    eventTypes: [
      {
        name: 'NODE_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)\/nodes\/(?<nodeId>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId, nodeId }) => ({
          jobId,
          nodeId,
          value: _update.value,
          localTime: _timestamp,
          jobSubPath: `${jobId}/nodes/${nodeId}`
        }),
        onEventGroup: async (events) => await writeNodeEventsToFirestore(events, 'NODE')
      },
      {
        name: 'JOB_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)$/,
        parse: async ({ _update, _timestamp, jobId }) => ({
          jobId,
          localTime: _timestamp
        }),
        onEventGroup: async (events) => await handleImpliedDeletes(events, 'JOB')
      },
      {
        name: 'NODES_DELETED',
        pathMatch: /photoheight\/jobs\/(?<jobId>[^\/]+)\/nodes$/,
        parse: async ({ _update, _timestamp, jobId }) => ({
          jobId,
          localTime: _timestamp
        }),
        onEventGroup: async (events) => await handleImpliedDeletes(events, 'NODES')
      }
    ]
  });
}

async function writeNodeEventsToFirestore(events, entityType) {
  // Get a list of all the unique job-nodes that were updated
  const jobSubPaths = [...new Set(events.map((event) => event.jobSubPath))];
  // Get the logged-in user's ID
  const userId = katapultAuth.user.uid;
  const userGroup = katapultAuth.userGroup;
  // Get a ref to the jobs list
  const jobsRef = FirebaseWorker.database().ref(`photoheight/jobs`);
  const jobOwnersByJobId = {};

  // For each job node, create geo-location event records for each node
  await Promise.all(
    jobSubPaths.map(async (jobSubPath) => {
      const nodeUpdate = events.find((event) => event.jobSubPath == jobSubPath);
      const _event = { ...nodeUpdate };
      const entityId = entityType === 'NODE' ? _event?.nodeId : null;

      // Get the job owner
      let jobOwner = jobOwnersByJobId[_event?.jobId];
      if (!jobOwner) {
        jobOwner = await jobsRef
          .child(`${_event?.jobId}/job_owner`)
          .once('value')
          .then((s) => s.val());
        jobOwnersByJobId[_event?.jobId] = jobOwner;
      }
      // Create the node-event record
      const nodeEvent = {
        node_path: `photoheight/jobs/${jobSubPath}`,
        entityId: entityId,
        entityType: entityType,
        job_id: _event?.jobId,
        owner_company_id: jobOwner,
        deleted_at: firebase.firestore.FieldValue.serverTimestamp(),
        deleted_by: {
          uid: userId,
          user_group: userGroup
        }
      };
      // Add the node-event to the firestore collection
      await firebase.firestore().doc(`jobs/${_event?.jobId}/entity_deleted_events/${entityId}`).set(nodeEvent);
    })
  );
}

async function handleImpliedDeletes(events, scope) {
  // Get the timestamp of the update (from the first event)
  const timestamp = firebase.firestore.Timestamp.fromMillis(events[0].localTime.toMillis());
  // Per event group, make lookup of job tracking settings and batch writes
  const trackingSettingsLookup = {};
  const userId = katapultAuth.user.uid;
  const userGroup = katapultAuth.userGroup;

  await Promise.all(
    events.map(async (event) => {
      // Get the project document
      const { trackingDisabled } = await getJobTrackingSettings(event.jobId, trackingSettingsLookup);

      // If tracking is disabled for this job, skip it
      if (trackingDisabled) return;

      // For deletes, we need to query for the existing values and mark them for deletion
      const projectDoc = firebase.firestore().doc(`jobs/${event.jobId}`);
      const queryConditions = getDeleteQueryConditions(event, scope);

      // Get the latest and historical events collections
      const nodesLastUpdatedCollection = projectDoc.collection(`nodes_last_updated`);
      // Make two queries for the two collections that will gather the documents to modify
      let nodesLastUpdatedQuery = nodesLastUpdatedCollection;
      for (const condition of queryConditions) {
        nodesLastUpdatedQuery = nodesLastUpdatedQuery.where(condition.field, '==', condition.value);
      }

      // Get the latest and historical events to modify
      const [nodesLastUpdatedSnapshot] = await Promise.all([nodesLastUpdatedQuery.get()]);

      nodesLastUpdatedSnapshot.docs.forEach(async (doc) => {
        let nodeData = doc.data();
        // Create the node-event record
        const nodeEvent = {
          node_path: nodeData.node_path,
          entityId: nodeData.node_id,
          entityType: scope,
          job_id: event.jobId,
          owner_company_id: nodeData?.owner_company_id,
          deleted_at: timestamp,
          deleted_by: {
            uid: userId,
            user_group: userGroup
          }
        };
        // Update "latest" doc so it reflects deletion by the actor
        await firebase.firestore().doc(`jobs/${event?.jobId}/entity_deleted_events/${nodeData.node_id}`).set(nodeEvent);
      });
    })
  );
}
async function getJobTrackingSettings(jobId, trackingSettingsLookup) {
  // Try to get the project ID from the lookup
  let trackingSettings = trackingSettingsLookup[jobId];

  // If it cannot be found, fetch it and add it to the lookup
  if (!trackingSettings) {
    const jobRef = FirebaseWorker.database().ref(`photoheight/jobs/${jobId}`);

    // See if tracking is disabled for this job
    const trackingDisabledSnapshot = await jobRef.child(`tracking_disabled`).once('value');

    // Compile the tracking settings
    trackingSettings = {
      trackingDisabled: Boolean(trackingDisabledSnapshot.val())
    };
    trackingSettingsLookup[jobId] = trackingSettings;
  }

  return trackingSettings;
}
function getDeleteQueryConditions(event, scope) {
  // We always want to limit to the job ID
  const conditions = [{ field: 'job_id', value: event.jobId }];

  // For any other scope besides "JOB", we need to add additional conditions
  if (scope === 'JOB_ATTRIBUTE') {
    conditions.push({ field: 'entity_type', value: 'JOB' }, { field: 'attribute', value: event.attributeName });
  } else if (scope === 'NODES') {
    conditions.push({ field: 'entity_type', value: 'NODE' });
  } else if (scope === 'NODE') {
    conditions.push({ field: 'entity_type', value: 'NODE' }, { field: 'entity_id', value: event.nodeId });
  }

  return conditions;
}
