import firebase from 'firebase/compat/app';
import { DateTime } from 'luxon';
import { DatabaseUpdateParser } from '../DatabaseUpdateParser.js';

export function getParser() {
  return DatabaseUpdateParser.fromConfig({
    globalPathFilter: /^photoheight\/jobs\/*./,
    eventTypes: [
      {
        name: 'NODE_LOCATION_SET',
        pathMatch: /^photoheight\/jobs\/(?<jobId>[^\/\s]+)\/nodes\/(?<nodeId>[^\/\s]+)\/(?<latOrLng>latitude|longitude)$/,
        parse: async ({ _update, _timestamp, jobId, nodeId, latOrLng }) => ({
          jobId,
          nodeId,
          type: latOrLng,
          value: _update.value,
          localTime: _timestamp,
          jobSubPath: `${jobId}/nodes/${nodeId}`
        }),
        onEventGroup: async (events) => await writeGeoEventsToFirestore(events, 'NODE')
      },
      {
        name: 'SECTION_LOCATION_SET',
        pathMatch:
          /^photoheight\/jobs\/(?<jobId>[^\/\s]+)\/connections\/(?<connectionId>[^\/\s]+)\/sections\/(?<sectionId>[^\/\s]+)\/(?<latOrLng>latitude|longitude)$/,
        parse: async ({ _update, _timestamp, jobId, connectionId, sectionId, latOrLng }) => ({
          jobId,
          connectionId,
          sectionId,
          type: latOrLng,
          value: _update.value,
          localTime: _timestamp,
          jobSubPath: `${jobId}/connections/${connectionId}/sections/${sectionId}`
        }),
        onEventGroup: async (events) => await writeGeoEventsToFirestore(events, 'SECTION')
      }
    ]
  });
}

async function writeGeoEventsToFirestore(events, entityType) {
  // Get a list of all the unique job-nodes that were updated
  const jobSubPaths = [...new Set(events.map((event) => event.jobSubPath))];
  // Get the logged-in user's ID
  const userId = katapultAuth.user.uid;
  const userGroup = katapultAuth.userGroup;
  // Get the local timestamp as a firestore timestamp
  const timestamp = firebase.firestore.Timestamp.fromMillis(DateTime.now().toMillis());

  // Get a ref to the jobs list
  const jobsRef = FirebaseWorker.database().ref(`photoheight/jobs`);
  const jobOwnersByJobId = {};

  // For each job node, create geo-location event records for each node
  await Promise.all(
    jobSubPaths.map(async (jobSubPath) => {
      // Filter to the events for this job-node
      const latUpdate = events.find((event) => event.jobSubPath == jobSubPath && event.type === 'latitude');
      const lngUpdate = events.find((event) => event.jobSubPath == jobSubPath && event.type === 'longitude');

      // Get the event data (or fetch it from the database if necessary)
      const _event = { ...latUpdate, ...lngUpdate };
      const latitude =
        latUpdate?.value ??
        (await jobsRef
          .child(`${jobSubPath}/latitude`)
          .once('value')
          .then((s) => s.val()));
      const longitude =
        lngUpdate?.value ??
        (await jobsRef
          .child(`${jobSubPath}/longitude`)
          .once('value')
          .then((s) => s.val()));
      const entityId = _event?.nodeId ?? _event?.sectionId;
      // If this is a section, include the connection as the parent
      const parent = entityType == 'SECTION' ? { entity_id: _event?.connectionId, entity_type: 'CONNECTION' } : null;

      // TODO (08-03-2023): This is a temporary system to enable stricter security rules for this data; remove when sharing objects exist in Firestore
      // Get the job owner
      let jobOwner = jobOwnersByJobId[_event?.jobId];
      if (!jobOwner) {
        jobOwner = await jobsRef
          .child(`${_event?.jobId}/job_owner`)
          .once('value')
          .then((s) => s.val());
        jobOwnersByJobId[_event?.jobId] = jobOwner;
      }

      // Create the geo-event record
      const geoEvent = {
        value: { latitude, longitude },
        entity_id: entityId,
        entity_type: entityType,
        parent: parent,
        entity_was_deleted: false,
        entity_path: `photoheight/jobs/${jobSubPath}`,
        job_id: _event?.jobId,
        job_owner: jobOwner,
        set_at: timestamp,
        set_by: {
          uid: userId,
          user_group: userGroup
        }
      };

      // Add the geo-event to the firestore collection
      await firebase.firestore().collection(`jobs/${_event?.jobId}/geo_events`).add(geoEvent);
    })
  );
}
