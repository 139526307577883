import firebase from 'firebase/compat/app';
import { DatabaseUpdateParser } from '../DatabaseUpdateParser.js';

export function getParser() {
  return DatabaseUpdateParser.fromConfig({
    globalPathFilter: /^photoheight\/jobs\/*./,
    eventTypes: [
      {
        name: 'NODE_CHANGED',
        pathMatch: /^photoheight\/jobs\/(?<jobId>[^\/\s]+)\/nodes\/(?<nodeId>[^\/\s]+)(?:\/.*)?$/,
        parse: async ({ _update, _timestamp, jobId, nodeId }) => ({
          jobId,
          nodeId,
          value: _update.value,
          localTime: _timestamp,
          jobSubPath: `${jobId}/nodes/${nodeId}`
        }),
        onEventGroup: async (events) => await writeNodeEventsToFirestore(events)
      }
    ]
  });
}

async function writeNodeEventsToFirestore(events) {
  // Get a list of all the unique job-nodes that were updated
  const jobSubPaths = [...new Set(events.map((event) => event.jobSubPath))];
  // Get the logged-in user's ID
  const userId = katapultAuth.user.uid;
  const userGroup = katapultAuth.userGroup;

  // Get a ref to the jobs list
  const jobsRef = FirebaseWorker.database().ref(`photoheight/jobs`);
  const jobOwnersByJobId = {};

  // For each job node, create geo-location event records for each node
  await Promise.all(
    jobSubPaths.map(async (jobSubPath) => {
      const nodeUpdate = events.find((event) => event.jobSubPath == jobSubPath);
      const _event = { ...nodeUpdate };
      const nodeId = _event?.nodeId;

      // Get the job owner
      let jobOwner = jobOwnersByJobId[_event?.jobId];
      if (!jobOwner) {
        jobOwner = await jobsRef
          .child(`${_event?.jobId}/job_owner`)
          .once('value')
          .then((s) => s.val());
        jobOwnersByJobId[_event?.jobId] = jobOwner;
      }

      // Create the node-event record
      const nodeEvent = {
        node_id: nodeId,
        node_path: `photoheight/jobs/${jobSubPath}`,
        job_id: _event?.jobId,
        owner_company_id: jobOwner,
        last_updated: firebase.firestore.FieldValue.serverTimestamp(),
        last_updated_by: {
          uid: userId,
          user_group: userGroup
        }
      };
      // Add the node-event to the firestore collection
      await firebase.firestore().doc(`jobs/${_event?.jobId}/nodes_last_updated/${nodeId}`).set(nodeEvent);
    })
  );
}
