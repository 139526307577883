// only allow certain browsers
import './browserCheck.js';
import { PolymerElement } from '@polymer/polymer';

// keep track if we are using mobile
self.isMobile = location.pathname.includes('mobile');
// keep track if the origin is a development environment
import { OriginIsDev } from '../modules/OriginIsDev.js';
self.originIsDev = OriginIsDev();

// Load firebase and make global.
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
self.firebase = firebase;

import './config.js';
import { ParseCSSColor } from '../modules/_deprecated/ParseCSSColor.js';
import '../js/firebaseWorker/firebaseWorker.js';
import AppName from './appName.js';
import { initSentry } from './sentry.js';
import 'polymerfire/polymerfire.js';
import '../elements/katapult-firebase-worker/katapult-firebase-worker.js';
import { init as InitFeatureFlags } from '../modules/FeatureFlags.js';
import { getParser as getGeoEventsParser } from '../modules/update-parsers/GeoEventsParser.js';
import { getParser as getLastUpdatedParser } from '../modules/update-parsers/LastUpdatedParser.js';
import { getParser as getAttributeEventParser } from '../modules/update-parsers/AttributeEventsParser.js';
import { getParser as getNodeEventsParser } from '../modules/update-parsers/NodeLastUpdatedParser.js';
import { getParser as getEntityDeletedParser } from '../modules/update-parsers/EntityDeletedParser.js';

/** Initialize firebase */
let appName = '';
let shouldUpdateSessionAppName = false;

// If you are a developer or you are on beta
if (self.originIsDev || self.location.origin.includes('beta.katapultpro.com')) {
  if (!self.originIsDev && typeof lockedAppName !== 'undefined' && lockedAppName) {
    shouldUpdateSessionAppName = true;
    appName = lockedAppName;
  } else {
    // If the app name doesn't exist in the session storage, then we should update it.
    shouldUpdateSessionAppName = !AppName.getSessionStorage();
    // Get the app name (either from session or local storage).
    appName = AppName.get();
  }
}

// Initialize Sentry on production (except for dev environments and prev domains to avoid false regressions)
if (!self.originIsDev && !self.location.origin.includes('prev')) initSentry(appName);

// set the config appName if necessary
if (appName) self.config.appName = appName;
if (firebase.apps.length) throw new Error('Failed to initialize firebase app: app already exists!');
let firebaseConfig = self.config.firebaseConfigs[self.config.appName];
if (!firebaseConfig)
  throw new Error('Failed to initialize firebase app: no firebase config found for app name ' + (self.config.appName || null) + '!');
else {
  firebase.initializeApp(firebaseConfig);

  // firebase.functions().useEmulator('localhost', 8080);
  // firebase.auth().useEmulator('http://localhost:9099');
  // firebase.firestore().useEmulator('localhost', 8083);

  // if we got the appName from indexedDB and we are using a database switching capable domain then set the appName in session storage
  // also if this is a locked page then update the appName in session storage to prevent false data
  if (shouldUpdateSessionAppName) AppName.setSessionStorage(self.config.appName);
  // Initialize FirebaseWorker
  FirebaseWorker._init();
  self.dispatchEvent(new CustomEvent('firebase-app-initialized'));

  // Set up feature flagging
  InitFeatureFlags(FirebaseWorker.database());

  // Enable offline persistence for Firestore if we are on a mobile device
  if (self.isMobile) {
    firebase
      .firestore()
      .enablePersistence({ synchronizeTabs: true })
      .then(() => {
        console.info('Enabled offline persistence for Firestore');
      })
      .catch((err) => {
        console.warn('Failed to enable offline persistence:', err);
      });
  }
}

class AppConfiguration {
  static get config() {
    return self.config;
  }
  constructor(polymerElement, options) {
    options = options || {};
    // Store a reference to the polymer element, the name of the property of the element which is to store the configuration data, and a local copy of configuration data.
    this.polymerElement = polymerElement || null;
    this.elementName = this.polymerElement ? `<${this.polymerElement.localName}>` : '(No Element Given)';
    this.configKey = options.propertyName || 'config';

    let localStorageFirebaseData = JSON.parse(self.localStorage.getItem(`${self.config.appName}_white_label`) || null);
    if (localStorageFirebaseData && !localStorageFirebaseData.error) {
      self.config.firebaseData = localStorageFirebaseData;
      // Write local data (in config.js) and default palette (or stored palette) to element.
      this.writeConfigToElement();
      this.applyPalette();
    }

    // Fetch app data stored in firebase.
    FirebaseWorker.ref('photoheight/white_label')
      .once('value')
      .then((snapshot) => {
        // Once loaded, update config, write the new data to the element, and update the styling of the element using any styling found in firebase.
        self.config.firebaseData = snapshot.val();
        this.writeConfigToElement();
        this.applyPalette();
        // Store firebase data in local storage for immediate use next time.
        self.localStorage.setItem(`${self.config.appName}_white_label`, JSON.stringify(self.config.firebaseData));
        // Run firebase data loaded callback.
        if (options.dataLoaded && typeof options.dataLoaded === 'function') options.dataLoaded(self.config);
      })
      .catch((err) => {
        console.warn('appConfiguration firebase data failed to load in ' + this.elementName + '\n', err);
      });

    // Listen for user permissions to be set
    if (self.katapultAuth) {
      katapultAuth.addEventListener('user-permissions-set', () => {
        // Setup action tracking.
        if (this.polymerElement.isKatapultPageElement) {
          // For each variant of job-id, create a property observer for action tracking. This won't be necessary once action tracking was been deprecated in favor of attribute tracking for PPL-KWS.
          ['jobId', 'jobid', 'job_id'].forEach((prop) => {
            this.polymerElement._createPropertyObserver(prop, (val) => this.registerActionTracking(val));
            let initVal = this.polymerElement.get(prop);
            if (initVal) this.registerActionTracking(initVal);
          });

          // Detatch any existing event parser.
          // Per https://github.com/KatapultDevelopment/photoheight/pull/3431 we may not need to detach the parsers since attaching new ones
          // of the same name might garbage-collect the existing ones.
          FirebaseWorker.detachUpdateParser('attribute-event-parser-v2');
          FirebaseWorker.detachUpdateParser('last-update-parser');
          FirebaseWorker.detachUpdateParser('geo-events-parser');
          FirebaseWorker.detachUpdateParser('node-events-parser');
          FirebaseWorker.detachUpdateParser('entity-deleted-parser');

          // Attach a parser to FirebaseWorker to track attribute events
          const attributeEventParserConfig = {
            actorUid: katapultAuth.user.uid,
            actorCompanyId: katapultAuth.userGroup
          };
          const attributeEventParser = getAttributeEventParser(attributeEventParserConfig);
          FirebaseWorker.attachUpdateParser('attribute-event-parser-v2', attributeEventParser);

          // Attach a listener to the element to track job last-updated events
          const lastUpdatedParserOptions = { uid: katapultAuth.user.uid };
          const lastUpdatedParser = getLastUpdatedParser(lastUpdatedParserOptions);
          FirebaseWorker.attachUpdateParser('last-update-parser', lastUpdatedParser);

          // Attach a listener to the element to track job geo-location events
          const geoEventsParser = getGeoEventsParser();
          FirebaseWorker.attachUpdateParser('geo-events-parser', geoEventsParser);

          // Attach a listener to the element to track job node events
          const nodeEventsParser = getNodeEventsParser();
          FirebaseWorker.attachUpdateParser('node-events-parser', nodeEventsParser);

          // Attach a listener to the element to track entity deleted events
          const entityDeletedParser = getEntityDeletedParser();
          FirebaseWorker.attachUpdateParser('entity-deleted-parser', entityDeletedParser);
        }
      });
    }
  }

  applyPalette() {
    // Loop through all keys in config.firebaseData.palette, convert them to css variable names, and apply the values to them.
    if (self.config.firebaseData.palette) {
      let palette = self.config.firebaseData.palette;
      let lightColor = '#FFFFFF';
      let darkColor = '#212121';
      // Ensure dark and light versions exist.
      ['primaryColor', 'secondaryColor'].forEach((x) => {
        if (!palette[x + 'Dark']) palette[x + 'Dark'] = palette[x];
        if (!palette[x + 'Light']) palette[x + 'Light'] = palette[x];
      });
      // Ensure default text colors exist.
      if (!palette.primaryTextColor) palette.primaryTextColor = darkColor;
      let rgb = ParseCSSColor(darkColor);
      let faded = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${rgb[3] - 0.2})`;
      if (!palette.primaryTextColorFaded) palette.primaryTextColorFaded = faded;
      // Calc text for color versions.
      ['primaryColor', 'primaryColorDark', 'primaryColorLight', 'secondaryColor', 'secondaryColorDark', 'secondaryColorLight'].forEach(
        (x) => {
          let color = this.colorBrightness(ParseCSSColor(palette[x])) > 0.6 ? darkColor : lightColor;
          let rgb = ParseCSSColor(color);
          let faded = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${rgb[3] - 0.2})`;
          if (!palette[x + 'TextColor']) palette[x + 'TextColor'] = color;
          if (!palette[x + 'TextColorFaded']) palette[x + 'TextColorFaded'] = faded;
        }
      );
      for (let key in palette) {
        let propertyName =
          '--' +
          key
            .replace(/\W+/g, '-')
            .replace(/([a-z\d])([A-Z])/g, '$1-$2')
            .toLowerCase();
        let val = palette[key];
        document.documentElement.style.setProperty(propertyName, val);
      }
      window.dispatchEvent(new CustomEvent('palette-applied'));
    }
  }

  colorBrightness(rgb) {
    // Takes in a color and determines how bright it is on a scale from 0-255
    return Math.sqrt(rgb[0] * rgb[0] * 0.241 + rgb[1] * rgb[1] * 0.691 + rgb[2] * rgb[2] * 0.068) / 255;
  }

  writeConfigToElement() {
    if (this.polymerElement && this.polymerElement instanceof PolymerElement) {
      // Set the configKey property on the element to be the configuration data.
      this.polymerElement.set(this.configKey, self.config);
      // Notify all paths on the element for config.firebaseData.*.
      for (let key in self.config.firebaseData) {
        this.polymerElement.notifyPath(this.configKey + '.firebaseData.' + key);
      }
    } else this.polymerElement[this.configKey] = self.config;
  }

  async registerActionTracking(jobId) {
    // Clear the existing action tracking models.
    FirebaseWorker.setActionTrackingModels(null);

    if (jobId == null) {
      console.debug('Action tracking and attribute tracking disabled in', this.elementName);
      return;
    }

    // Return if jobId is an empty string
    if (!jobId) return;

    const jobRef = FirebaseWorker.ref(`photoheight/jobs/${jobId}`);

    // Get the job's tracking model
    const trackingModelRef = jobRef.child(`metadata/tracking_model`);
    // Detach tracking model listener
    if (this.jobTrackingModelListener) trackingModelRef.off('value', this.jobTrackingModelListener);
    // Try to get action tracking models for this job.
    this.jobTrackingModelListener = trackingModelRef.on('value', async (s) => {
      let trackingModel = s.val();
      // If we have a tracking model, try to set up action tracking
      if (trackingModel) {
        let jobCreator = await jobRef
          .child(`job_creator`)
          .once('value')
          .then((s) => s.val());
        let actionTrackingModels = await FirebaseWorker.ref(
          `photoheight/company_space/${jobCreator}/models/action_tracking/${trackingModel}`
        )
          .once('value')
          .then((s) => s.val());
        if (actionTrackingModels) FirebaseWorker.setActionTrackingModels(actionTrackingModels, katapultAuth.userGroup);
        console.debug('Action tracking configured in', this.elementName);
      }
      // Otherwise, unset the tracking model
      else {
        FirebaseWorker.setActionTrackingModels({}, katapultAuth.userGroup);
        console.debug('Action tracking disabled in', this.elementName);
      }
    });
  }
}
self.AppConfiguration = AppConfiguration;
