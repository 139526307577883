// Check browser.
let userAgent = navigator.userAgent.toLowerCase();

let checkNavigator = (types) => {
  return (types || []).some((x) => {
    return userAgent.indexOf(x) !== -1;
  });
};

self.browserInfo = {
  safari: checkNavigator(['safari']) && !checkNavigator(['chrome']),
  iphone: checkNavigator(['iphone']),
  ipad: checkNavigator(['ipad']),
  chrome: checkNavigator(['chrome']),
  firefox: checkNavigator(['firefox']),
  edge: checkNavigator(['edge/']) || checkNavigator(['edg/'])
};

let supportedBrowser =
  (browserInfo.chrome || browserInfo.iphone || browserInfo.ipad || (browserInfo.safari && window.location.href.includes('/mobile'))) &&
  !browserInfo.edge;
// temporary fix for LGE using edge
// if edge, and their session storage doesn't have the browserCheck flag
if (window.location.href.indexOf('lge.') != -1 && browserInfo.edge) {
  if (!sessionStorage.getItem(`browserCheck`)) {
    // set browserCheck flag
    sessionStorage.setItem(`browserCheck`, true);
    document.body.innerHTML = '';
    document.body.style.backgroundColor = '#003e51';
    document.body.style.color = 'white';
    document.body.style.height = '100%';
    document.body.style.width = '100%';
    document.body.style.position = 'absolute';
    document.body.style.margin = '0';
    document.body.style.display = 'flex';
    document.body.style.flexDirection = 'column';
    document.body.style.alignItems = 'center';
    document.body.style.justifyContent = 'center';
    document.body.style.fontFamily = 'Roboto';
    document.body.innerHTML =
      '<p style="margin: 48px; font-size: 20vmin; font-weight: bold;">Uh oh!</p><p style="margin: 48px; margin-top: -24px; font-size: 5vmin; text-align: center">Edge is not a supported browser, be aware that certain functionalities may not perform as expected.</p><a style="text-decoration: none;" onclick="function continueToKatapult() {location.reload()} continueToKatapult()"><div style="background-color: #007299; color: white; padding: 0.7em 0.57em; border-radius: 999px;">CONTINUE TO KATAPULT PRO</div></a>';
  }
} else {
  if (!supportedBrowser && !navigator.standalone) {
    // Browser unsupported.
    document.body.innerHTML = '';
    document.body.style.backgroundColor = '#003e51';
    document.body.style.color = 'white';
    document.body.style.height = '100%';
    document.body.style.width = '100%';
    document.body.style.position = 'absolute';
    document.body.style.margin = '0';
    document.body.style.display = 'flex';
    document.body.style.flexDirection = 'column';
    document.body.style.alignItems = 'center';
    document.body.style.justifyContent = 'center';
    document.body.style.fontFamily = 'Roboto';
    document.body.innerHTML =
      '<p style="margin: 48px; font-size: 20vmin; font-weight: bold;">Uh oh!</p><p style="margin: 48px; margin-top: -24px; font-size: 5vmin;">Your browser is not supported</p><a style="text-decoration: none;" href="https://www.google.com/chrome"><div style="background-color: #007299; color: white; padding: 0.7em 0.57em; border-radius: 999px;">TRY GOOGLE CHROME INSTEAD</div></a>';
  }
}
