/**
 * Checks if the origin is a development environment
 * @return {boolean} true if the origin is a development environment
 */
export function OriginIsDev() {
  const origin = window.location.origin;
  return (
    origin.includes('vfs.cloud9') ||
    origin.includes('localhost') ||
    origin.includes('githubpreview.dev') ||
    origin.includes('54.') ||
    origin.includes('127.0.0.1') ||
    origin.includes('github.dev') ||
    origin.includes('192.168')
  );
}
