import '../../js/deep-diff.min.js';
import { MutableData } from '@polymer/polymer/lib/mixins/mutable-data.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import { ToArray } from '../../modules/ToArray.js';

class KatapultFirebaseWorker extends MutableData(PolymerElement) {
  static get is() {
    return 'katapult-firebase-worker';
  }
  static get properties() {
    return {
      _attached: {
        type: Boolean,
        value: false
      },
      path: {
        type: String
      },
      childEvents: {
        type: Boolean,
        value: false
      },
      disabled: {
        type: Boolean,
        value: false,
        observer: '_disabledChanged'
      },
      addKeyToData: {
        type: Boolean,
        value: false
      },
      orderByKey: {
        type: Boolean
      },
      orderByChild: {
        type: String
      },
      equalTo: {
        type: String
      },
      limitToFirst: {
        type: Number
      },
      limitToLast: {
        type: Number
      },
      listeners: {
        type: Object,
        value: () => ({})
      },
      loaded: {
        type: Boolean,
        value: false,
        notify: true
      },
      loading: {
        type: Boolean,
        value: true,
        notify: true
      },
      _log: {
        type: Boolean,
        value: false
      },
      nullValue: {
        type: Object,
        value: null
      },
      _nullValue: {
        type: Object,
        computed: 'calcNullValue(nullValue, dataAsArray)',
        observer: '_nullValueChanged'
      },
      data: {
        type: Object,
        value: null,
        notify: true
      },
      dataAsArray: {
        type: Boolean,
        value: false
      },
      _gotFirstData: {
        type: Boolean,
        value: false
      },
      _newRef: {
        type: Object
      },
      _ref: {
        type: Object
      }
    };
  }
  static get observers() {
    return [
      '_updateLoading(disabled, _gotFirstData, path)',
      '_updateRef(path, orderByKey, orderByChild, equalTo, limitToFirst, limitToLast, disabled)',
      '_updateListeners(_newRef, childEvents)',
      'dataChanged(data.*)'
    ];
  }

  constructor() {
    super();
    this._lazyUpdateListeners = function () {
      setTimeout(() => this._updateListeners());
    }.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this._updateListeners();

    // Add trigger to update event listeners when firebase is initialized.
    window.addEventListener('firebase-app-initialized', this._lazyUpdateListeners);
  }

  child(key) {
    return this.get(`data.${key}`);
  }
  dataChanged(changeRecord) {
    this.dispatchEvent(new CustomEvent('change-record', { detail: changeRecord }));
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    this._detachListeners();
    window.removeEventListener('firebase-app-initialized', this._lazyUpdateListeners);
  }
  ready() {
    super.ready();
  }
  applyNullValue(data) {
    if (data == null) return this._nullValue;
    return data;
  }
  calcNullValue() {
    if (this.dataAsArray) return [];
    if (this.nullValue != null) return this.nullValue;
    return null;
  }
  _callback(s, type) {
    if (type == 'value') {
      // Get data and apply null value.
      let data = this.applyNullValue(s.val());
      // Convert to array if applicable.
      if (this.dataAsArray) data = ToArray(data);
      if (this.addKeyToData && this.path && data) data.$key = this.path.split('/').pop();
      // Set data.
      this.set('data', data);
      // Set got first data flag.
      if (!this._gotFirstData) this._gotFirstData = true;
      this.dispatchEvent(new CustomEvent('change', { detail: s.val() })); // TODO: Any elements listening to this event should probably switch to on-change
      this.log(`Received ${type} event at ${this.path}.`, data);
      // let update = s.update ? s.update() : null;
      // if (update) this.dispatchEvent(new CustomEvent('update', {detail: update}));
    } else if (this._gotFirstData) {
      if (type == 'child_added') {
        if (this.get(`data.${s.key}`) == null) this.set(`data.${s.key}`, s.val());
        else this.log(`Ignored ${type} event; because data already exists`);
      } else if (type == 'child_changed') {
        this.set(`data.${s.key}`, s.val());
        this.log(`Received ${type} event at ${this.path}/${s.key}`);
      } else if (type == 'child_removed') {
        this.set(`data.${s.key}`, null);
        this.log(`Received ${type} event at ${this.path}/${s.key}`);
      }
    } else {
      // this.log(`Ignored ${type} event; still waiting for first data`);
    }
  }
  _disabledChanged() {}
  _attachListeners() {
    if (AppConfiguration && !this._listenersAttached && this._ref) {
      // If childEvents is true, use child change events instead of watching the whole location.
      let listenerTypes = this.childEvents ? ['child_added', 'child_changed', 'child_removed'] : ['value'];
      // If we are using child events, get all of the data at this location once so we know when all of the data has actually been loaded (indeterminate with child_added events).
      if (this.childEvents) {
        let temp = { ignore: false };
        if (this._gotFirstData) temp.ignore = true;
        this._ref.once('value').then((s) => {
          if (!temp.ignore) this._callback(s, 'value');
        });
      }
      // Loop listener types and setup listeners.
      listenerTypes.forEach((type) => {
        this.listeners[type] = this._ref.on(type, (s) => this._callback(s, type));
        this.log(`Attached on ${type} listener at ${this.path}`);
      });
      // Note that listeners are attached.
      this._listenersAttached = true;
    }
  }
  _detachListeners() {
    if (this._listenersAttached && this._ref) {
      for (let type in this.listeners) {
        this._ref.off(type, this.listeners[type]);
        this.log(`Detached on ${type} listener at ${this.path}`);
      }
      this.listeners = {};
      this._listenersAttached = false;
    }
  }
  _updateListeners() {
    this._detachListeners();
    this._gotFirstData = false;
    this.data = this._nullValue;
    this._ref = this._newRef;
    this._attachListeners();
  }
  _nullValueChanged() {
    this.data = this.applyNullValue(this.data);
  }
  _updateLoading() {
    this.loading = !this.disabled && !this._gotFirstData && this.path != null;
    this.loaded = this._gotFirstData;
  }
  _updateRef() {
    // Only update ref if path exists and is valid
    let pathIsValid = this.path && !this.path.includes('//') && !this.path.endsWith('/');
    if (pathIsValid && !this.disabled) {
      let ref = FirebaseWorker.ref(this.path);
      if (this.orderByKey != null) ref = ref.orderByKey();
      if (this.orderByChild != null && this.orderByChild != '') ref = ref.orderByChild(this.orderByChild);
      if (this.equalTo != null) ref = ref.equalTo(this.equalTo);
      if (this.limitToFirst != null) ref = ref.limitToFirst(this.limitToFirst);
      if (this.limitToLast != null) ref = ref.limitToLast(this.limitToLast);
      this._newRef = ref;
    } else {
      this._newRef = null;
    }
  }
  log() {
    this._log && console.debug(`%ckatapult-firebase-worker:`, 'color: #3F51B5', ...arguments);
  }
}
window.customElements.define(KatapultFirebaseWorker.is, KatapultFirebaseWorker);
